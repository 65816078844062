
import './iko.css'
import './post.css'
// import './bootstrap.css'
import './iko-core.css'
import './iko-unit.css'
import './custom-frontend.css'
import './custom.css'
import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Home.css";
import "./style.css";
import LogoNew from '../../../../assets/images/logo.png'
import Ethan from '../../../../assets/images/ethan.jpg'
import Leo from '../../../../assets/images/leo.jpg'
import Lily from '../../../../assets/images/lily.jpg'
import Sophie from '../../../../assets/images/sophie.jpg'



const HomeData = () => {
  return (
    <></>
  )
};

export default HomeData;
